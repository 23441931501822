.projectsDiv {
  justify-content: space-between;
}

.project_menu {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.project_menu_item {
  color: var(--main-text);
  margin: 0.5rem;
  padding: 0.5rem;
  transition: ease-in-out 200ms;
}

.project_menu_item.active {
  border-left: 1px solid var(--highlight);
  border-bottom: 2px solid var(--highlight);
  border-radius: 0.5rem;
}

.project_menu_item:hover {
  font-size: large;
  cursor: default;
}

.project_details {
  padding: 1rem;
  width: 70%;
  min-height: 18rem;
  color: white;
  transition: ease-in-out 200ms;
}

.project_title {
  justify-content: space-between;
  align-items: center;
}

.project_description{
  padding: 1rem;
}

.project_action_div {
  justify-content: space-between;
  margin: 0rem 1rem;
}

.project_action_item {
  text-decoration: none;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  margin-inline-start: 1rem;
}

.project_action_item path {
  fill: #d1d1d2;
}

.project_action_item:hover path {
  fill: var(--highlight);
}

.toolsUsed {
  margin: 1.5rem 2rem;
}

@media screen and (max-width: 720px) {
  .projectsDiv {
    flex-direction: column-reverse;
    padding: 0.5rem 1rem;
    row-gap: 1.5rem;
  }
  .project_menu {
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    column-gap: 1rem;
    padding-bottom: 0.5rem;
  }

  .project_menu_item{
    margin: 0.25rem;
    padding: 0.25rem 0.5rem;
  }
  .project_details{
    width: 100%;
  }
  .toolsUsed{
    margin: 1rem 1rem;
  }
}

@media screen and (max-width: 450px){
  .section-title{
    font-size: 5vw;
  }
} 