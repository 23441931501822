.portfolioHeader,
.routesDiv {
  display: flex;
  /* height: 2rem; */
  justify-content: space-between;
  align-items: center;
}

.portfolioHeader {
  position: sticky;
  top: 0rem;
  background-color: var(--primary-black);
  z-index: 999;
  padding: 1rem 0rem;
}

.routes {
  text-decoration: none;
  color: var(--primary-text);
  margin-inline: 0.5rem;
  border: 2px solid var(--primary-text);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: ease-in-out 200ms;
  cursor: default;
}

.routes:hover {
  transform: scale(1.1, 1.1);
  color: var(--highlight);
  border: 2px solid var(--highlight);
  transition: ease-in-out 200ms;
}

.ascLogo {
  border: px solid var(--primary-text);
  border-radius: 0.5rem;
}

.ascLogo img {
  height: 3rem;
  aspect-ratio: 3/2;
  object-fit: contain;
  transition: ease-in-out 500ms;
}

.ascLogo:hover {
  transform: scale(1.1, 1.1);
  border-color: var(--highlight);
  border-radius: 5rem;
  transition: ease-in-out 500ms;
}
