.header_nav{
    color: white;
    background-color: #778DA9;
}

.App{
    margin: 2rem auto;
    max-width: 1200px;
    min-width: 350px;
    overflow: auto;
    padding : 0rem 2rem;
}

@media screen and (max-width: 1250px) {
    .App{
        margin-inline:0.5rem;
    }
}

@media screen and (max-width: 720px) {
    .App{
        padding:0rem 0.5rem;
        margin: 0.5rem 0.5rem;
    }
}