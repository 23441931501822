.workAlertDiv {
  display: flex;
  flex-direction: row;
  border: 2px solid white;
  border-radius: 1rem;
  margin: 1rem 1rem;
  padding: 0.5rem 0.5rem;
  justify-content: space-evenly;
}

.work {
  margin-block: 1rem;
  padding: 1rem 1rem;
  color: aliceblue;
}
.work > .d-flex-row {
  justify-content: space-between;
}

.workTimeline {
  display: flex;
  flex-direction: column;
  width: 27%;
}

.timeline_detail {
  border-right: 1px solid white;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
  margin: 0rem 0.5rem;
  font-size: large;
  transition: ease-in-out 200ms;
}

.timeline_detail .selectionLogo {
  display: none;
  transition: ease-in-out 200ms;
}

.timeline_detail.active {
  border-right: 4px solid var(--highlight);
  transition: ease-in-out 200ms;
}

.timeline_detail.active h5 {
  color: var(--highlight);
  transition: ease-in-out 200ms;
}

.timeline_detail.active .selectionLogo {
  display: block;
  width: 2rem;
  height: 2rem;
  fill: var(--highlight);
  transition: ease-in-out 200ms;
}

.timeline_detail:hover {
  font-size: larger;
  cursor: default;
  transition: ease-in-out 200ms;
}

.timeline_detail_title {
  justify-content: space-between;
  align-items: center;
  transition: ease-in-out 200ms;
  padding: 1rem 0;
}

.workDetails {
  width: 73%;
  padding: 1rem;
  transition: ease-in-out 200ms;
}

.workDetails div,
.workDetails ul {
  transition: ease-in-out 200ms;
}

.workDuration {
  padding: 0.5rem 0;
  opacity: 0.6;
}

#onJobHeading {
  text-align: center;
}
.workTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.workTitle_designation {
}

.onJob_project {
  min-height: 20vh;
  padding: 1rem 1rem;
  transition: ease-in-out 200ms;
}

.onJob_project_title {
  padding-bottom: 0.5rem;
  text-decoration: underline;
}

.onJob_project_details {
  padding: 0 0.5rem;
}

/*-- On Job Nav --*/
.onJob_nav,
.onJob_nav div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.onJob_nav {
  margin: 3rem 1rem;
  height: 0.25rem;
  justify-content: space-between;
}

.onJob_nav_btns {
  border: 1px solid white;
  padding: 0.75rem 1rem;
  transition: ease-in-out 200ms;
}

.onJob_nav_btns:hover {
  background-color: var(--highlight);
  border-radius: 1rem;
}

.onJob_nav_child {
  margin: 0 0.25rem;
  padding: 0.25rem 0.25rem;
  border: 2px solid white;
}

.onJob_nav_child.active {
  background-color: whitesmoke;
}

@media screen and (max-width: 720px) {
  .work{
    padding: 0.5rem 1rem;
  }

  .work > .d-flex-row {
    flex-direction: column;
    row-gap: 1.5rem;
  }

  .workTimeline {
    flex-direction: row;
    column-gap: 3rem;
    justify-content: space-evenly;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 0.5rem;

  }

  .timeline_detail {
    border-bottom: 1px solid var(--secondary-text);
    border-right: none;
  }

  .timeline_detail.active {
    border-bottom: 4px solid var(--highlight);
    border-right: none;

  }

  .timeline_detail.active .selectionLogo {
    display: none;
  }

  .timeline_detail_title{
    padding: 0.5rem 0;
  }

  .workDetails{
    width: 100%;
  }
}
