.footer_text{
    /* margin-inline: auto; */
    text-align: center;
    margin: auto 1rem;
}

.footer_images{
    justify-content: center;
}

.footer_images img{
    margin: 1rem;
    width:15%;
    min-width: 100px;
    border-radius: 1rem;
    aspect-ratio: 3/2;
    object-fit: contain;
}