@import url('https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&display=swap');

*{
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: 100%;
    line-height: 1.15
}

/* COLOR CONSTANTS */
:root{
    --primary-text: #d1d1d1;
    --secondary-text: #d1d1d7;
    --background: #1d1d20;
    --primary-black:#000000;
    --secondary-black:#444;
    --highlight: rgb(0,255,255);
}

body {
    background-color: var(--background);
    color: var(--primary-text);
    font-family: "Reddit Mono", monospace;
    background: black;

}
