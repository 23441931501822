.profileSummary {
  display: flex;
  padding: 2rem 2rem;
  justify-content: space-between;
  align-content: center;
  height: 90vh;
  align-items: center;
}

.avatar {
  border: 2px solid white;
  border-radius: 2rem;
  height: fit-content;
  align-self: center;
}

.avatar img {
  mix-blend-mode: color-dodge;
}

.summary {
  margin: 2rem 2rem;
  color: white;
  padding: 2rem 4rem 2rem 1rem;
  border-radius: 1rem;
}
.summary > .profileName {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--primary-text);
  text-align: left;
  margin-block: 1rem;
  margin-inline: 0 1rem;
  color: var(--highlight);
}

.profileTagline {
  font-size: 1.7rem;
  font-weight: 400;
  opacity: 0.6;
  transition: ease-in-out 10000ms;
}

.profileTagline span {
  color: var(--highlight);
}

.profileDescription {
  margin: 1rem 0;
  font-size: 1.2rem;
}

.profile_actions {
  align-items: center;
  margin-top: 1.5rem;
}

#knowMore {
  text-decoration: none;
  color: var(--primary-text);
  margin-inline: 0.5rem;
  border: 2px solid var(--primary-text);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: ease-in-out 200ms;
  cursor: default;
  margin-left: 0;
}

#knowMore:hover {
  transform: scale(1.1, 1.1);
  color: var(--highlight);
  border: 2px solid var(--highlight);
  transition: ease-in-out 200ms;
}

.whatIKnow {
  display: flex;
  padding: 2rem 2rem;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  flex-wrap: wrap;
  row-gap: 1rem;
}

.techIconsSvg {
  fill: var(--primary-text);
  width: 6rem;
  height: 5rem;
}

.techIconsSvg path {
  fill: var(--primary-text);
  stroke: var(--primary-text);
  stroke-width: 0.1;
}

#expressLogo path {
  stroke-width: 8;
}

/* --- profilePic flips --- */

.scene .card {
  position: relative;
  width: 13rem;
  height: 16rem;
  color: white;
  cursor: pointer;
  transition: 1s ease-in-out;
  transform-style: preserve-3d;
}

.scene .card:hover {
  transform: rotateY(0.5turn);
}

.scene .card .card__face {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: 1s ease-in-out;
  -webkit-box-reflect: below 0
    linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.1));
}

.scene .card .card__face img {
  width: 13rem;
  height: 16rem;
  object-fit: cover;
  border-radius: 2rem;
}

.scene .card .card__face--back {
  transform: rotateY(0.5turn);
}

@media screen and (max-width: 920px) {
  .profileSummary {
    flex-direction: column-reverse;
  }
  .summary {
    padding: 2rem 1rem 2rem 1rem;
  }
  .scene .card,
  .scene .card .card__face img {
    width: 13rem;
    height: 16rem;
  }
}

@media screen and (max-width: 720px) {
  .profileSummary {
    padding: 0.5rem;
  }

  .summary {
    margin: 1rem;
  }

  .scene .card,
  .scene .card .card__face img {
    width: 10rem;
    height: 13rem;
  }
}

@media screen and (max-width: 500px) {
  .profileTagline span {
    display: block;
  }

  .scene .card,
  .scene .card .card__face img {
    width: 20vw;
    height: 25vw;
  }
}

@media screen and (max-width: 390px) {
  .profileTagline span {
    display: inline;
  }
  .summary {
    margin: 1rem;
    padding: 1rem;
  }

  .scene {
    display: none;
  }
}
